* {
  margin: 0;
  padding: 0;
  width: 100%;
}

// .App {
  // min-width: 100vw;
  // min-width: 600px;
  // background-color: pink;
// }

html { font-size: 62.5%;}

::-webkit-scrollbar {
  width: 0px;
}

/* --- REUSABLE --- */


/* SMALLER ELEMENTS */
/* 24 / 30 / 36 -- approved font sizes*/ 
h1,
h2,
h3,
h4,
h5 {
  color: #555;
}

h1 {
  font-size: 36px;
  font-weight: 100;
  letter-spacing: 2px;
}

h2 {
  margin-bottom: 48px;
  font-size: 36px;
  letter-spacing: -0.5px;
}

h3 {
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: 40px;
}

h4 {
  font-size: 24px;
  font-style: italic;
  text-align: center;
  top: 15px;
  margin-bottom: 30px;
}

p {
  font-size: 22px;
  line-height: 1.5;
  margin-bottom: 15px;
}

ul,
ol {
  position: relative;
  width: 90%;
  color: #555;
  margin: 10px 0px 10px 0px;
}

li {
  font-size: 20px;
  margin: 5px 0px 5px 40px;
  text-align: left;
  width: 95%;
}

li:first-child {
  width: 100%;
  font-weight: bold;
  margin-bottom: 0;
  margin: 10px 0px 10px 0px;
  list-style-type: none;
  text-transform: uppercase;
  position: relative;
}

li span {
  width: 120%;
  height: 2px;
  margin-left: 20px;
  top: 15px;
  border-top: solid 1.5px #555;
  position: absolute;
}



/* --- MEDIA QUERIES --- */
@media (max-width:550px){
  li:first-child {
    margin-left: -30px;
  }
  li {
    margin: 0px 0px 10px 10px;
  }
}

@media (max-width:400px) {
  li:first-child {
    margin-left: 0px;
  }
}